import { datadogRum } from '@datadog/browser-rum';
import request from 'api/index';
import { SabalBankAccount } from 'api/payments/types';
import { getSiteStage } from 'api/siteStage/siteStage';
import { BankAccountValidationResponse, UpdateUser, User, UserSettings, UserStages } from 'api/user/types';
import { UtilityRateFlowLoginResponse, UtilityRatePlanScope } from 'api/utilityRatePlan/types';
import { isImpersonatedUser } from 'utils/auth';

export const synchronizeUserData = async (user: User): Promise<User> => {
  let synchronizedUser: User = { ...user };

  // try to synchronize with site stage data
  if (user.stage === UserStages.PTO && !user.settings?.hasActivatedSystem) {
    try {
      const siteStageData = await getSiteStage();
      const settings: UserSettings = { hasActivatedSystem: true, inverterType: siteStageData.inverterType };
      if (siteStageData.hasProductionData) {
        if (isImpersonatedUser()) {
          // PUT /user call for read-only users
          synchronizedUser.settings = { ...synchronizedUser.settings, ...settings };
        } else {
          // update user settings to match site stage data
          const updatedUser = await putUser({ settings });
          if (updatedUser) {
            synchronizedUser = updatedUser;
          }
        }
      }
    } catch (e) {
      datadogRum.addError(e);
    }
  }

  return synchronizedUser;
};

export async function getUser(): Promise<User> {
  const result = await request({ url: '/user' });
  return synchronizeUserData(result.data);
}

export async function putUser(user: UpdateUser): Promise<User> {
  const result = await request({ method: 'put', url: '/user', data: user });
  return result.data;
}

export async function getBankAccountValidationResult(requestId?: string): Promise<BankAccountValidationResponse> {
  const result = await request({ url: `/user/bank-account-validation-results/${requestId}` });
  return result.data;
}

export async function getSabalPaymentMethod(): Promise<SabalBankAccount> {
  const result = await request({ url: `/user/sabal-payment-method` });
  return result.data;
}

export async function getUtilityRateFlowLogin(scope: UtilityRatePlanScope): Promise<UtilityRateFlowLoginResponse> {
  const result = await request({ method: 'post', url: `/user/light-energy-flow-link`, data: { scope } });
  return result.data;
}
