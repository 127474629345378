import { setupIonicReact } from '@ionic/react';
import { ThemeProvider as PalemttoComponentsThemeProvider, ResponsiveProvider } from '@palmetto/palmetto-components';
import { PrismicProvider } from '@prismicio/react';
import classNames from 'classnames';
import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { client } from 'api/prismic/prismic';
import Header from 'components/Header/Header';
import { LoadingPageProvider } from 'components/LoadingPage/LoadingPage';
import { ModalProvider } from 'components/Modal/Modal';
import Routes, { ROUTES } from 'components/Routes/Routes';
import ApiProvider from 'components/_app/ApiProvider/ApiProvider';
import AuthProvider from 'components/_app/AuthProvider/AuthProvider';
import ErrorBoundary from 'components/_app/ErrorBoundary/ErrorBoundary';
import ThemeProvider from 'components/_app/ThemeProvider/ThemeProvider';
import TranslationProvider from 'components/_app/TranslationProvider/TranslationProvider';
import CustomerTermsModal from 'components/_modals/CustomerTermsModal/CustomerTermsModal';
import Tracking from 'components/_tracking/Tracking';
import useDatadog from 'hooks/_app/useDatadog';
import useInAppReview from 'hooks/_app/useInAppReview';
import useAppListener from 'hooks/_app/useNativeAppListener';
import { usePushNotifications } from 'hooks/_app/usePushNotifications';
import useThemedFavicon from 'hooks/_app/useThemedFavicon';

import ArticleModal from 'components/ArticleModal/ArticleModal';
import { useArticleModal } from 'hooks/useArticleModal';
import styles from './App.module.scss';

// always use iOS mode for Ionic
setupIonicReact({
  mode: 'ios',
});

export const App: React.FC = () => {
  useThemedFavicon();
  useInAppReview(true);
  useDatadog();
  usePushNotifications();
  useAppListener();

  const { pathname } = useLocation();

  const { articleModalState, dismiss } = useArticleModal();

  const routeConfig = ROUTES.find((route) => route.path === pathname);

  const showBackgroundImage = routeConfig?.hideBackgroundImage !== true;
  return (
    <div className={classNames(styles.pageContainer, { [styles.backgroundImage]: showBackgroundImage })}>
      <Header />
      <Routes />
      <CustomerTermsModal />
      {articleModalState.uid && (
        <ArticleModal uid={articleModalState.uid} isOpen={articleModalState.isOpen} onDismiss={dismiss} />
      )}
    </div>
  );
};

const ConnectedApp: React.FC = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <ApiProvider>
        <AuthProvider>
          <TranslationProvider>
            <ResponsiveProvider>
              <PalemttoComponentsThemeProvider>
                <ThemeProvider>
                  <LoadingPageProvider>
                    <ModalProvider>
                      <PrismicProvider client={client}>
                        <Tracking>
                          <App />
                        </Tracking>
                      </PrismicProvider>
                    </ModalProvider>
                  </LoadingPageProvider>
                </ThemeProvider>
              </PalemttoComponentsThemeProvider>
            </ResponsiveProvider>
          </TranslationProvider>
        </AuthProvider>
      </ApiProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
export default ConnectedApp;
