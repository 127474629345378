// TODO: convert to a hook so we don't have to pass in user and flags
// https://palmetto.atlassian.net/browse/PA-3157

import { Property } from 'api/properties/types';
import { User, UserSource, UserStages } from 'api/user/types';
import { LDFlags, SABAL_FINANCING_SOURCES } from 'config';
import { AssociatedEntityTypes } from 'core/associatedEntity';
import PERMITTED_UTILITY_PROVIDERS_MAP from 'utils/permittedUtilityProvidersMap';
import { checkAccessFromSharedContentToken } from 'utils/sharedContent';

export type IFeatureConfig = (user?: User, flags?: LDFlags, property?: Property) => boolean;

const onboardingFeedItems: IFeatureConfig = (user?: User) => Boolean(user && user.stage === UserStages.prePTO);

const siteSurvey: IFeatureConfig = (user: User | undefined, flags: LDFlags | undefined) =>
  onboardingFeedItems(user) || siteSurveyV2(user, flags);

const siteSurveyV2: IFeatureConfig = (user: User | undefined) => {
  if (user) {
    // allow authenticated user if they're in the onboarding stage
    return onboardingFeedItems(user);
  }

  // allow unauthenticated user if a shared content token with site-survey access is present
  return checkAccessFromSharedContentToken('site-survey');
};

const orderStatus: IFeatureConfig = (user?: User) => Boolean(user && user.stage === UserStages.prePTO);

const subscription: IFeatureConfig = (user?: User) => {
  const SubscriptionDisplayStages: (UserStages | undefined)[] = [UserStages.PTO];
  return Boolean(SubscriptionDisplayStages.includes(user?.stage) && user?.source !== UserSource.subscriptionSolar);
};

const service: IFeatureConfig = (user?: User) => Boolean(user && user.settings?.hasActivatedSystem);

const celebration: IFeatureConfig = () => false;

const ambassadorW9: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.ambassadorW9);

const referAFriend: IFeatureConfig = (user?: User, flags?: LDFlags) => {
  return (
    Boolean(flags?.referAFriend) &&
    Boolean(!user?.associatedEntities?.some((e) => e.entityType === AssociatedEntityTypes.sabalOrganization)) &&
    Boolean(user && user.source !== UserSource.luminance)
  );
};

const energyAdvisorPage: IFeatureConfig = (user, flags, property) => {
  return (
    (Boolean(flags?.energyAdvisorPage) &&
      Boolean(user?.associatedEntities?.find((e) => e.entityType === AssociatedEntityTypes.luminanceEstimate))) ||
    (Boolean(flags?.energyAdvisorV2) && Boolean(property?.hasEnergyAdvisorData))
  );
};

const paymentsV3: IFeatureConfig = (user?: User, flags?: LDFlags) => {
  return Boolean(user && user.source && SABAL_FINANCING_SOURCES.has(user.source) && flags?.paymentsV3);
};

const financeAccountDetail: IFeatureConfig = (user?: User, flags?: LDFlags) => {
  return Boolean(user && user.source && SABAL_FINANCING_SOURCES.has(user.source) && flags?.paymentsV3FinanceAccount);
};

const dashboard: IFeatureConfig = (user?: User) => Boolean(user && user.source !== UserSource.luminance);

const payment: IFeatureConfig = (user?: User) => Boolean(user && user.source !== UserSource.luminance);

const gridUsageChart: IFeatureConfig = (user, flags, property) => {
  return Boolean(user?.stage === UserStages.PTO && property?.hasGridConnection && flags?.displayGridUsageChart);
};

const energyProfile: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.energyProfile);

const utilityProvider: IFeatureConfig = (user?, flags?, property?) => {
  return Boolean(
    flags?.utilityProvider &&
      user?.stateCode &&
      PERMITTED_UTILITY_PROVIDERS_MAP[user.stateCode] &&
      !property?.hasGridConnection,
  );
};

export default {
  siteSurvey,
  siteSurveyV2,
  orderStatus,
  service,
  celebration,
  ambassadorW9,
  subscription,
  referAFriend,
  energyAdvisorPage,
  paymentsV3,
  financeAccountDetail,
  payment,
  dashboard,
  gridUsageChart,
  energyProfile,
  utilityProvider,
};
