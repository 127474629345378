import { PaymentMethodType } from 'api/payments/types';
import { AssociatedEntity } from 'core/associatedEntity';
import { USStateCode } from 'utils/stateNames';

export type ConnectStatus = 'started' | 'authorizing' | 'completed';
export type InverterType = 'solaredge' | 'enphase' | 'other';

interface ArcadiaPlugIntervals {
  credentialsEntryState?: 'UTILITY_NOT_SUPPORTED' | 'CREDENTIALS_SUBMITTED' | 'CREDENTIALS_SUCCESS';
  providerId: string;
}
export interface UserSettings {
  connectStatus?: ConnectStatus | null;
  inverterType?: InverterType | null;
  previousOwner?: string;
  systemId?: string;
  serialNumber?: string;
  solarEdgeSiteId?: string;
  dismissedStartOnboardingModal?: boolean;
  completedActivationSteps?: boolean;
  hasApprovedPanelPlacement?: boolean;
  hasActivatedSystem?: boolean;
  electricityProvider?: string;
  hasViewedReferAFriend?: boolean;
  hasViewedEnergyAdvisorPage?: boolean;
  hasViewedEnergyProfilePage?: boolean;
  arcadiaPlugIntervals?: ArcadiaPlugIntervals;
  fakeDoorTests?: Record<string, boolean>;
}

export type UserSegmentation = Record<string, string | number | boolean>;

export enum UserRoles {
  ambassador = 'ambassador',
}

export enum UserStages {
  prePTO = 'prePTO',
  PTO = 'PTO',
}

export interface AmbassadorStats {
  ordered: number;
  installed: number;
  earned: number;
  fewerMilesDriven?: number;
  treesGrownForTenYears?: number;
  fewerPoundsOfCoalBurned?: number;
}

export enum UserSource {
  salesMember = 'sales-member',
  salesRevamp = 'sales-revamp',
  sungevity = 'sungevity',
  subscriptionSolar = 'subscription-solar',
  palmettoPPA = 'palmetto-ppa',
  epc = 'epc',
  luminance = 'luminance',
}

export interface UserConfig {
  ambassador: boolean;
  supportedPaymentTypes: PaymentMethodType[];
}
export interface ApiUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  streetAddress: string;
  city: string;
  stateCode: USStateCode;
  zip: string;
  pictureUrl: string;
  auth0Id: string;
  auth0UpdatedAt: string;
  updatedAt: string;
  createdAt: string;
  languageCode: LocalizationLanguages;
  fcmToken?: string;
  isTestAccount?: boolean;
  entitlements?: {
    palmettoProtect?: string; // pp1, pp2, pp3, pp4
    serviceProvider?: string; // Sunnova, etc.
  };
  propertyIds?: string[];
  settings?: UserSettings;
  segmentation?: UserSegmentation;
  roles?: UserRoles[];
  stage?: UserStages;
  stripeCustomerId?: string;
  ambassadorStats: AmbassadorStats;
  ambassadorW9Status: string;
  source?: UserSource;
  config: UserConfig;
  associatedEntities?: AssociatedEntity[];
  signatures?: Signature[];
  financeAccounts?: FinancialAccount[];
}

type EnitySignedType = 'questionnaire';

interface EntitySigned {
  id: string;
  type: EnitySignedType;
}

export interface Signature {
  createdAt: string;
  enteredName: string;
  entitySigned: EntitySigned;
  ipAddress: string;
  metaData: {
    questions?: string[];
    [key: string]: unknown;
  };
}

export type User = ApiUser;

export interface ApiUserExternalData {
  languageCode: LocalizationLanguages;
}

export enum LocalizationLanguages {
  English = 'en-US',
  Spanish = 'es-US',
}

type EditableFields = 'fcmToken' | 'settings' | 'firstName' | 'lastName' | 'phone' | 'email';

export type UpdateUser = Partial<Pick<User, EditableFields>>;

export type BankAccountValidationStatus = 'pending' | 'valid' | 'invalid' | 'error' | 'unavailable';
export type BankAccountInvalidReason = 'accountClosed' | 'noMatchOrNotFound';
export interface BankAccountValidationResponse {
  status?: BankAccountValidationStatus;
  invalidReason?: BankAccountInvalidReason;
}
export interface BankAccountValidationResponseWithRequestLimit extends BankAccountValidationResponse {
  requestLimitMet: boolean;
}

export enum FinancialAccountProductType {
  ppa = 'ppa',
  lease = 'lease',
}

export enum FinancialAccountProduct {
  solar = 'solar',
  heatPump = 'heatPump',
  utilityRatePlan = 'utilityRatePlan',
}

export enum FinancialAccountSource {
  sabal = 'sabal',
  lightEnergy = 'lightEnergy',
}
export interface FinancialAccount {
  _id: string;
  accountId: string;
  source: FinancialAccountSource;
  product: FinancialAccountProduct;
  productType: FinancialAccountProductType;
}
